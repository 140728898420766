.root {
    margin: 16px;

    .manageButton {
        margin-top: 16px;
        padding: 8px;
        background-color: #1D3053;
        border-radius: 2px;

        .manageText {
            color: white;
            font-size: 13px;
            font-weight: 300;
            text-transform: none;
        }
    }

    .loadingContainer {
        align-items: center;
    }
}