.root {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .dialogTitleContainer {
        .title {
            font-size: 20px;
            font-weight: 400;
        }
    }
    
    .contentContainer {
        .valueField {
            :global(.MuiInputBase-input) {
                padding: 4px 8px !important;
            }
        }

        .buttonContainer {
            width: 100%;
            margin-top: 8px;
            align-items: center;
            justify-content: flex-end;

            .button {
                min-width: 60px;
                text-transform: none;
                margin-left: 8px;
                padding: 3px;
                border: 1px solid #778398;
            }

            .buttonText {
                font-size: 13px;
                font-weight: 300;
                color: #1D3053;
                text-transform: none;
            }
        }
    }
}

