.root {
    display: grid;
    grid-template-columns: 50% auto 50%;
    width: 100%;
    margin-top: 29px;

    .detailsLeftContainer {
        display: flex;        
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-right: 16px;

        .dateText {
            font-size: 16px;
            font-weight: 200;
            color: rgba(#1d3053, 0.6);
        }

        .dateDescriptionText {
            font-size: 10px;
            font-weight: 200;
            color: #1d3053;
        }
    }

    .detailsSeparator {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .separatorIcon {
            margin-top: 4px;
            width: 15px;
            height: 15px;
            color: #172642;
        }

        .separatorHighestIcon {
            margin-top: 4px;
            width: 15px;
            height: 15px;
            color: #A38C5E;
        }
    }

    .detailsRightContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 16px;
        
        .bidText {
            font-size: 14px;
            font-weight: 300;
            color: rgba(#1d3053, 0.6);
        }

        .userContainer {            
            display: flex;
            flex-direction: row;
            align-items: center;

            .userText {
                font-size: 11px;
                font-weight: 200;
                color: #1d3053;
            }

            .gavelIcon {
                margin-left: 9px;
                width: 13px;
                height: 13px;
                color: #a38c5e;
            }

            .gavelBase {
                margin-left: -6px;
                font-size: 14px;
                font-weight: 400;
                color: #a38c5e;
                line-height: 16px;
            }
        }
    }

    .itemSeparator {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .itemSpacer {
            width: 2px;
            background-color: #172642;
            height: 24px;
            margin-bottom: 12px;
        }
    }
}