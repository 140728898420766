.root {
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 50, 102, 0.08);
    border-radius: 3px;
    padding: 0;

    .statusButton {
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(#ffffff, 0);
        border-radius: 0;
        color: #141414;
        padding: 2px 8px;

        .statusLabel {
            font-weight: 400;
            font-size: 14px;
            text-transform: none;
        }

        .countContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #141414;
            background-color: rgba(0, 25, 51, 0.12);
            border-radius: 4px;
            margin-left: 8px;

            .countLabel {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 10px;
                font-weight: 400;
                margin: 1px 4px;
            }
        }
    }

    .statusButtonSelected {
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(#b9b6b2, 0.8) !important;
        border-radius: 3px !important;
        background-color: white !important;
        color: #141414;
        padding: 2px 8px;
        box-shadow: 0 1px 0px 0px rgba(2, 26, 51, 0.05) !important;

        .statusLabel {
            font-weight: 400;
            font-size: 14px;
        }

        .countContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: white;
            background-color: #a38c5e;
            border-radius: 4px;
            margin-left: 8px;

            .countLabel {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 10px;
                font-weight: 400;
                margin: 1px 4px;
            }
        }
    }
}