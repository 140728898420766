.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contentContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        .imageContainer1 {
            width: 100%;
            overflow: hidden;

            .auctionSellImage {
                width: 100%;
                object-fit: fill;
            }
        }

        .titleContainer {
            margin: 28px 32px 0 32px;

            .titleText {
                font-size: 28px;
                font-weight: 200;
                color: #212F51;
                text-transform: uppercase;
            }
        }

        .titleContainerMobile {
            margin: 10px 16px 0 16px;

            .titleText {
                font-size: 28px;
                font-weight: 200;
                color: #212F51;
                text-transform: uppercase;
            }
        }

        .iconsContainer {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .contactRowContainer {
                display: grid;
                grid-template-columns: auto auto;
                row-gap: 24px;
                column-gap: 24px;

                a {
                    text-decoration: none;
                    color: #212f51;
                }

                .emailIcon {
                    width: 68px;
                    height: 68px;
                }

                .phoneIcon {
                    width: 60px;
                    height: 60px;
                }

                .instagramIcon {
                    width: 64px;
                    height: 64px;
                }

                .twitterIcon {
                    width: 64px;
                    height: 64px;
                }

                .contactTextContainer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    .contactText {
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}