.root {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .loadingContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 48px;

        .loadingText {
            font-size: 18px;
            font-weight: 300;
            color: #1d3053;
            margin-left: 12px;
        }

        .loader {
            width: 32px;
            height: 32px;
            border: 5px solid #cdb079;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 36px;
            font-weight: 400;
            color: #4d5b77;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: min(770vw, 552px);
    }

    .watchImageTypeContainer {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;

        .showroomWarn {
            margin-top: 10px;
        }
    }

    .watchImageContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;

        .showroomImage {
            width: 380px;
        }

        .imagesContainer {
            // display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(283px, 1fr));
            // margin: 6px 32px 28px 32px;
            // row-gap: 32px;
            // column-gap: 28px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            // grid-template-rows: repeat(8, 5vw);
            grid-gap: 1.5rem; 
        }

        .watchNoImageContainer {
            width: 250px;
            height: 250px;
            justify-content: center;
            align-items: center;

            & svg {
                color: #b5b5b5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #b5b5b5;
            }
        }
    }

    .conditionsContainer {
        margin-top: 8px;
        width: 100%;

        .iconContainer {
            color: #a28957;
            background-color: #f5f5f5;
            border-radius: 4px;
            margin-right: 32px;
            align-items: center;
            justify-items: center;

            .icon {
                width: 40px;
                height: 40px;
                padding: 8px;
            }
        }
    }

    .acceptConditionsContainer {
        margin-top: 18px;
        margin-bottom: 22px;
        width: 100%;
    }

    .dividerContainer {
        margin-left: 16px;
        margin-right: 16px;
        align-items: center;
        justify-items: center;
        margin-bottom: 12px;

        .divider {
            width: 100%;
            height: 1px;
            background-color: #ebebeb;
        }
    }

    .saveContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        :global(.Mui-disabled) {
            background-color: #babec7 !important;
        }

        .saveButton {
            background-color: #172642;
            border-radius: 8px;
            padding: 10px 24px;
        }

        .saveText {
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            text-transform: none;
        }
    }
}

.rootMobile {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .loadingContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 16px;

        .loadingText {
            font-size: 12px;
            font-weight: 300;
            color: #1d3053;
            margin-left: 8px;
        }

        .loader {
            width: 18px;
            height: 18px;
            border: 5px solid #cdb079;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 18px;
            font-weight: 400;
            color: #4d5b77;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: 70vw;
    }

    .watchImageTypeContainer {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;

        .showroomWarn {
            margin-top: 10px;
        }
    }

    .watchImageContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 8px;

        .showroomImage {
            width: 250px;
        }

        .imagesContainer {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(283px, 1fr));
            margin: 6px 32px 28px 32px;
            row-gap: 32px;
            column-gap: 28px;
        }

        .watchNoImageContainer {
            width: 250px;
            height: 250px;
            justify-content: center;
            align-items: center;

            & svg {
                color: #b5b5b5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #b5b5b5;
            }
        }
    }

    .conditionsContainer {
        margin-top: 8px;
        width: 100%;

        .iconContainer {
            color: #a28957;
            background-color: #f5f5f5;
            border-radius: 4px;
            margin-right: 8px;
            align-items: center;
            justify-items: center;

            .icon {
                width: 20px;
                height: 20px;
                padding: 4px;
            }
        }
    }

    .acceptConditionsContainer {
        margin-top: 8px;
        margin-bottom: 11px;
        width: 100%;
    }

    .dividerContainer {
        margin-left: 8px;
        margin-right: 8px;
        align-items: center;
        justify-items: center;
        margin-bottom: 6px;

        .divider {
            width: 100%;
            height: 2px;
            background-color: #ebebeb;
        }
    }

    .saveContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 16;

        :global(.Mui-disabled) {
            background-color: #babec7 !important;
        }

        .saveButton {
            background-color: #172642;
            border-radius: 8px;
            padding: 5px 12px;
        }

        .saveText {
            font-size: 11px;
            font-weight: 300;
            color: #ffffff;
            text-transform: none;
        }
    }
}
