.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contentContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        .imageContainer1 {
            width: 100%;
            overflow: hidden;
    
            .auctionBuyImage {
                width: 100%;
                object-fit: fill;
            }
        }
    
        .filterPanel {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            vertical-align: middle;        
    
            .statusFilterContainer {
                margin: 24px 32px;
            }
    
            .sellerTypeContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                
                .sellerTypeLabel {
                    font-size: 14px;
                    font-weight: 300;
                    color: rgba(#1d3053, 0.6);
                }
    
                .sellerTypeDropdownContainer {
                    margin-left: 8px;
                    margin-top: 1px;
    
                    .sellerTypeDropdown {
                        font-size: 14px;
                        font-weight: 300;
                        color: #1d3053;
                    }
    
                    .sellerTypeDropdownSelected {
                        background-color: white;
                    }
                }
            }
    
            .sortByContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 32px;
                
                .sortByLabel {
                    font-size: 14px;
                    font-weight: 300;
                    color: rgba(#1d3053, 0.6);
                }
    
                .sortByDropdownContainer {
                    margin-left: 8px;
                    margin-top: 1px;
    
                    .sortByDropdown {
                        font-size: 14px;
                        font-weight: 300;
                        color: #1d3053;
                    }
    
                    .sortByDropdownSelected {
                        background-color: white;
                    }
                }
            }
    
            .spacer {
                flex-grow: 1;
            }
    
            .searchContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 0 32px;
                min-width: 200px;
    
                :global(.MuiInputBase-root) {
                    background-color: white;
                }
    
                :global(.MuiInputBase-input) {
                    padding: 4px 0 5px 0;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: white;
                    color: rgba(#1d3053, 0.6);
                }
    
                :global(.MuiFilledInput-root) {
                    padding-left: 0px;
                }
    
                :global(.MuiInputAdornment-positionStart) {
                    margin: 2px 4px 0 2px !important;
                }
    
                .searchIconContainer {
                    .searchIcon {
                        font-size: 18px;
                        color: #A38C5E;
                    }
                }            
            }
        }
    
        .filterPanelMobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            vertical-align: middle;
            margin-top: 3px;
    
            .statusFilterContainerMobile {
                margin: 16px 0 16px 16px;
    
                :global(.MuiInputBase-root::before) {
                    border-bottom-width: 0 !important;
                }
    
                :global(.MuiInputBase-root::after) {
                    border-bottom-width: 0 !important;
                }
    
                .statusDropdown {
                    font-size: 14px;
                    font-weight: 300;
                    color: #1d3053;
                }
            
                .statusDropdownSelected {
                    background-color: white;
                }
            }
    
            .sellerTypeContainerMobile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                
                .sellerTypeLabel {
                    font-size: 14px;
                    font-weight: 300;
                    color: rgba(#1d3053, 0.6);
                }
    
                .sellerTypeDropdownContainer {
                    margin: 1px 16px 0 8px;
    
                    :global(.MuiInputBase-root::before) {
                        border-bottom-width: 0 !important;
                    }
    
                    :global(.MuiInputBase-root::after) {
                        border-bottom-width: 0 !important;
                    }
    
                    .sellerTypeDropdown {
                        font-size: 14px;
                        font-weight: 300;
                        color: #1d3053;
                    }
    
                    .sellerTypeDropdownSelected {
                        background-color: white;
                    }
                }
            }
    
            .sortByContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 32px;
                
                .sortByLabel {
                    font-size: 14px;
                    font-weight: 300;
                    color: rgba(#1d3053, 0.6);
                }
    
                .sortByDropdownContainer {
                    margin-left: 8px;
                    margin-top: 1px;
    
                    .sortByDropdown {
                        font-size: 14px;
                        font-weight: 300;
                        color: #1d3053;
                    }
    
                    .sortByDropdownSelected {
                        background-color: white;
                    }
                }
            }
    
            .spacer {
                flex-grow: 1;
            }
    
            .searchContainerMobile {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                margin: 3px 6px;
        
                :global(.MuiInputBase-root) {
                    background-color: white;
                }
        
                :global(.MuiInputBase-input) {
                    padding: 4px 0 5px 0;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: white;
                    color: rgba(#1d3053, 0.6);
                }
        
                :global(.MuiFilledInput-root) {
                    padding-left: 0px;
                }
        
                :global(.MuiInputAdornment-positionStart) {
                    margin: 2px 4px 0 2px !important;
                }
        
                .searchIconContainer {
                    .searchIcon {
                        font-size: 18px;
                        color: #A38C5E;
                    }
                }            
            } 
        }
    
        .noItemsContainer {
            margin: 16px 32px 56px 32px;

            .noItemsText {
                font-size: 16px;
                font-weight: 200;
                color: rgba(#000000, 0.6);    
            }
        }

        .noItemsContainerMobile {
            margin: 16px 16px 32px 16px;

            .noItemsText {
                font-size: 14px;
                font-weight: 200;
                color: rgba(#000000, 0.6);    
            }
        }      
    
        .itemsContainer {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(283px, 1fr));
            margin: 6px 32px 28px 32px;
            row-gap: 32px;
            column-gap: 28px;
        }
    
        .itemsContainerMobile {
            margin: 6px 4px 28px 4px;
    
            .itemsContainerInner {
                display: grid;
                grid-template-columns: 50% 50%;
                row-gap: 24px;
            }
        }
    
        .pagesContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 32px;
            margin-bottom: 71px;
        }
    }
}
