.root {
    width: 100%;
    display: flex;
    flex-direction: column;

    .imageContainer1 {
        width: 100%;
        overflow: hidden;

        .auctionSellImage {
            width: 100%;
            object-fit: fill;
        }
    }

    .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin: 32px;

        .titleText {
            font-size: 28px;
            font-weight: 200;
            color: #1D3053;
            text-transform: uppercase;
        }

        .comingSoonText {
            margin-left: 12px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(#1D3053, 0.78);
            text-transform: uppercase;
        }
    }

    .contentContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        margin-bottom: 185px;

        .partContainer1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            position: relative;

            .backgroundIcon {
                z-index: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.5;
            }

            .partContainer2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 300px;
                min-width: 300px;
                z-index: 10;

                .headerText {
                    color: #212F51;
                    font-size: 18px;
                    font-weight: 300;
                }

                .bodyText {
                    margin-top: 16px;
                    color: #5C5B59;
                    font-size: 14px;
                    font-weight: 300;
                    text-transform: none;
                }
            }
        }
    }

    .contactContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        .emailContainer {
            margin: 40px 32px 20px 32px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .emailText {
                color: #000000;
                font-size: 14px;
                font-weight: 300;
            }

            .formContainer {
                margin-top: -14px;
                margin-left: -12px;
                width: 330px;
                height: 280px;
                border: 0;
            }
        }
    }
}

.rootMobile {
    width: 100%;
    display: flex;
    flex-direction: column;

    .imageContainer1 {
        width: 100%;
        overflow: hidden;

        .auctionSellImage {
            width: 100%;
            object-fit: fill;
        }
    }

    .titleContainer {
        display: flex;
        flex-direction: column;
        margin: 16px;

        .titleText {
            font-size: 28px;
            font-weight: 200;
            color: #1D3053;
            text-transform: uppercase;
        }

        .comingSoonText {
            font-size: 16px;
            font-weight: 400;
            color: rgba(#1D3053, 0.78);
            text-transform: uppercase;
        }
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15vh;

        .partContainer1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            position: relative;

            .backgroundIcon {
                z-index: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.5;
            }

            .partContainer2 {
                display: flex;
                flex-direction: column;
                z-index: 10;
                margin: 16px 16px 32px 16px;

                .headerText {
                    color: #212F51;
                    font-size: 18px;
                    font-weight: 300;
                }

                .bodyText {
                    margin-top: 16px;
                    color: #5C5B59;
                    font-size: 14px;
                    font-weight: 300;
                    text-transform: none;
                }
            }
        }
    }

    .contactContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        .emailContainer {
            margin: 40px 32px 54px 32px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .emailText {
                color: #000000;
                font-size: 14px;
                font-weight: 300;
            }

            .formContainer {
                margin-top: -14px;
                margin-left: -12px;
                width: 330px;
                height: 280px;
                border: 0;
            }
        }
    }
}