.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contentContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .imageContainer1 {
            width: 100%;
            overflow: hidden;

            .auctionSellImage {
                width: 100%;
                object-fit: fill;
            }
        }

        .titleContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 16px 24px;

            .leftTitleContainer {
                display: flex;
                flex-direction: column;
                // margin: 11px 32px;

                .titleText {
                    text-transform: uppercase;
                    color: #212f51;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: 40px; /* 142.857% */
                }

                .faqLink {
                    font-size: 14px;
                    font-weight: 700;
                    color: #1d3053;
                }
            }

            .rightTitleContainer {
                width: 100px;
                justify-self: end;

                .trustPilotImage {
                    width: 100%;
                    object-fit: fill;
                }
            }
        }

        .titleContainerMobile {
            margin: 16px 24px;

            .titleText {
                text-transform: uppercase;
                color: #212f51;
                font-size: 28px;
                font-style: normal;
                font-weight: 200;
                line-height: 40px; /* 142.857% */
            }
            .rightTitleContainer {
                display: flex;
                justify-content: center;
            }
        }

        .infoContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;

            .leftInfoContainer {
                display: flex;
                flex-direction: column;
                margin: 0px 24px;

                .infoText {
                    color: #1d3053;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.5px;
                }

                .faqLink {
                    font-size: 16px;
                    font-weight: 700;
                    color: #1d3053;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.5px;
                }
            }

            .rightInfoContainer {
                display: flex;
                flex-direction: column;
                margin: 0px 24px;
            }
        }

        .infoContainerMobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            .leftInfoContainer {
                display: flex;
                flex-direction: column;
                margin: 0px 24px;

                .infoText {
                    color: #1d3053;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.5px;
                }

                .faqLink {
                    font-size: 16px;
                    font-weight: 700;
                    color: #1d3053;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.5px;
                }
            }

            .rightInfoContainer {
                margin: 16px 24px;
            }
        }

        .detailsContainer {
            display: grid;
            width: 100%;

            .watchDetailsInfo {
                margin-top: 32px;
                color: #1d3053;
                font-size: 18px;
                font-weight: 300;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.5px;
            }
            .watchDetailsContainer {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-template-rows: auto auto auto auto 1fr;
                width: 100%;
                margin-top: 32px;

                .watchBrandContainer {
                    margin: 0 50px 40px 0;

                    .watchBrandInput {
                        color: #000;

                        :global(.MuiFormLabel-root) {
                            color: #4d5b77;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                            color: #000;
                        }
                    }
                }

                .watchModelContainer {
                    margin: 0 0 40px 50px;

                    .watchModelInput {
                        color: #000;

                        :global(.MuiFormLabel-root) {
                            color: #4d5b77;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                            color: #000;
                        }
                    }
                }

                .watchModelNumberContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 0 50px 40px 0;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }

                    .watchModelNumberInput {
                        color: #505050;
                        margin-top: 8px;

                        :global(.MuiFormLabel-root) {
                            color: #4d5b77;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                            color: #000;
                        }
                    }
                }

                .watchYearContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 0 0 40px 50px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }

                    .watchYearInput {
                        color: #505050;
                        margin-top: 8px;

                        :global(.MuiFormLabel-root) {
                            color: #4d5b77;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 20px;
                            letter-spacing: 0.25px;
                            color: #000;
                        }
                    }
                }

                .papersContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 0 50px 0px 0px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }
                }

                .boxContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 0 0px 0 50px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }
                }

                .submitContainer {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: flex-end;

                    .submitButton {
                        border-radius: 2px;
                        border: 1px solid #778398;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        background-color: #fff;
                        text-transform: none;

                        .submitText {
                            color: #1d3053;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 16px;
                        }
                    }
                }
            }
        }

        .detailsContainerMobile {
            display: flex;
            flex-direction: column;
            width: 100%;

            .watchDetailsInfo {
                margin-top: 32px;
                color: #1d3053;
                font-size: 18px;
                font-weight: 300;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.5px;
            }

            .fileUploadContainer {
                display: flex;
                flex-direction: column;
                margin: 16px;
            }

            .watchDetailsContainer {
                display: flex;
                flex-direction: column;
                width: 100%;

                .watchBrandContainer {
                    margin: 16px;

                    .watchBrandInput {
                        color: #505050;

                        :global(.MuiFormLabel-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }

                .watchModelContainer {
                    margin: 16px;

                    .watchModelInput {
                        color: #505050;

                        :global(.MuiFormLabel-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }

                .watchModelNumberContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 16px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }

                    .watchModelNumberInput {
                        color: #505050;
                        margin-top: 8px;

                        :global(.MuiFormLabel-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }

                .watchYearContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 16px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }

                    .watchYearInput {
                        color: #505050;
                        margin-top: 8px;

                        :global(.MuiFormLabel-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }

                        :global(.MuiInputBase-root) {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }

                .papersContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 16px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }
                }

                .boxContainer {
                    display: flex;
                    flex-direction: column;
                    margin: 16px;

                    .questionText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #1d3053;
                    }

                    .switchContainer {
                        align-items: center;
                        margin-top: 12px;

                        .switchText {
                            font-size: 16px;
                            font-weight: 200;
                            color: rgba(#000000, 0.87);
                        }
                    }
                }

                .submitContainer {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: flex-end;

                    .submitButton {
                        border-radius: 2px;
                        border: 1px solid #778398;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        background-color: #fff;
                        text-transform: none;

                        .submitText {
                            color: #1d3053;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 16px;
                        }
                    }
                }

                .submitContainerMobile {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: flex-end;

                    .submitButton {
                        border-radius: 2px;
                        border: 1px solid #778398;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        background-color: #fff;
                        text-transform: none;

                        .submitText {
                            color: #1d3053;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 16px;
                        }
                    }
                }
            }
        }

        .authContainer {
            padding: 12px;
            margin-top: 34px;
            display: inline-flex;
            padding: 12px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            border-radius: 8px;
            background: #fff;
            /* Elevations/200 */
            box-shadow:
                0px 4px 4px 0px rgba(14, 16, 17, 0.1),
                0px 1px 3px 0px rgba(14, 16, 17, 0.1);

            .authText {
                color: #1d3053;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.5px;
            }

            .authButton {
                background-color: #172642;

                .authBtnText {
                    font-size: 13px;
                    font-weight: 300;
                    color: #fff;
                    text-transform: none;
                }
            }
        }

        .authContainerMobile {
            padding: 12px;
            margin-top: 34px;
            display: inline-flex;
            padding: 12px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            border-radius: 8px;
            background: #fff;
            /* Elevations/200 */
            box-shadow:
                0px 4px 4px 0px rgba(14, 16, 17, 0.1),
                0px 1px 3px 0px rgba(14, 16, 17, 0.1);

            .authText {
                color: #1d3053;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.5px;
            }

            .authButton {
                background-color: #172642;

                .authBtnText {
                    font-size: 13px;
                    font-weight: 300;
                    color: #fff;
                    text-transform: none;
                }
            }
        }
    }
}
