.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-width: 620px;
}

.rootMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-width: 292px;
}

.tabsContainer {
    margin: 28px 32px;
    
    .tabContentContainer {
        :global(.MuiBox-root) {
            padding: 17px 8px;
        }

        .summaryText {
            font-size: 14px;
            font-weight: 300;
            color: #1d3053;
        }
    }
}

.tabsContainerMobile {
    margin: 14px;

    .tabDropdown {
        font-size: 14px;
        font-weight: 300;
        color: #1d3053;
    }

    .tabDropdownSelected {
        background-color: white;
    }
}

.tabContentMobile {
    margin-top: 16px;
    width: 100%;
    height: 100%;
}

.tabHeader {
    align-items: center;            
}

.headerIcon {
    margin-left: 18px;
    font-size: 16px;
}

.headerIconSelected {
    margin-left: 18px;
    font-size: 16px;
    fill: #1D3053;
}

.headerText {
    margin-left: 8px !important;
    margin-right: 18px !important;
    margin-top: 2px !important;
    font-size: 13px !important;
    font-weight: 300 !important;
}

.headerTextSelected {
    margin-left: 8px !important;
    margin-right: 18px !important;
    margin-top: 2px !important;
    font-size: 13px !important;
    font-weight: 300 !important;
    color: #1D3053 !important;
}

.biddingHistoryIcon {
    margin-left: 18px;
    width: 24px;
    height: 24px;
    stroke: #717171;
}

.biddingHistoryIconSelected {
    margin-left: 18px;
    width: 24px;
    height: 24px;
    stroke: #1D3053;
}

.loginContainer {
    .loginText {
        font-size: 14px;
        font-weight: 300;
        color: #1d3053;
    }

    .loginButton {
        margin-top: 8px;
        padding: 8px;
        background-color: #1D3053;
        border-radius: 2px;

        .loginButtonText {
            color: white;
            font-size: 13px;
            font-weight: 300;
            text-transform: none;
        }
    }
}