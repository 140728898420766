.root {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.06);

    .firstRow {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        padding: 16px 32px 24px 32px;
    }

    .firstRowMobile {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        padding: 16px 16px 8px 16px;
    }

    .headerLeft {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .headerMiddle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logoIcon {
            width: 400px;
            height: 36px;
        }
    }

    .headerMiddleMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 200px;

        .logoIcon {
            width: 200px;
            height: 18px;
        }
    }
    
    .headerRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .spacer {
        width: 1px;
        height: 40%;
        background-color: #e0e0e0;
        margin: 0 4px;
    }

    .navContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 300;
        padding-bottom: 20px;

        a {
            text-decoration: none;
            color: rgba(#1D3053C7, 0.78);
        }

        .navSpacer {
            width: 1px;
            height: 16px;
            background-color: #e0e0e0;
            margin: 0 40px;
        }
    }

    .iconButton {
        color: #212f51;
    
        & svg {            
            font-size: 20px;
        }        
    }
}

.menuContent {
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 100%;

    .closeButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        .closeButton {
            color: #212f51;
            margin: 7px 2px;
        
            & svg {            
                font-size: 14px;
            }        
        }
    }

    .menuLogoContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .menuLogo {
            margin: 8px 30px;
        }
    }

    .contactContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 5px 0 11px 0;

        a {
            text-decoration: none;
            color: #505050;
        }

        .contact {
            font-size: 14px;
            font-weight: 200;
            color: #505050;
        }

        .contactSpacer {
            width: 1px;
            height: 80%;
            background-color: #C5C4C2;
            margin: 0 11px;
        }
    }

    .horizontalDividerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .horizontalDivider {
            height: 1px;
            width: calc(100% - 64px);
            margin-left: 32px;
            background-color: #C5C4C2;
        }
    }
    
    .menuContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .menuOptionsContainer {
            width: calc(100% - 16px);

            .menuTree {
                margin: 17px 18px 2px 18px;

                .menuOptionHeaderContainer {
                    margin-top: 10px;
                }

                .menuOptionHeader {
                    font-size: 14px;
                    font-weight: 300;
                    color: #152850;
                    padding: 8px;
                }

                .menuOptionFirstContainer {
                    margin-top: 4px;
                }

                .menuOption {
                    font-size: 13px;
                    font-weight: 300;
                    color: #505050;
                    padding: 8px;
                }
            }
        }
    }

    .bottomRowContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .loginButtonContainer {
            width: 100%;
            justify-content: center;
            align-items: center;
    
            .loginButton {
                margin: 0 18px 18px 18px;
                flex-grow: 1;
                background-color: #1d3053;
                border: 1px solid #1d3053;
                border-radius: 2px;
            }

            .manageButton {
                margin: 0 9px 18px 18px;
                width: 50%;
                background-color: white;
                border: 1px solid #778398;
                border-radius: 2px;
            }

            .loginOutButton {
                margin: 0 18px 18px 9px;
                width: 50%;
                background-color: #1d3053;
                border: 1px solid #1d3053;
                border-radius: 2px;
            }

            .manageText {
                font-size: 13px;
                font-weight: 300;
                color: #1D3053;
                text-transform: none;
            }
    
            .logInOutText {
                font-size: 13px;
                font-weight: 300;
                color: #ffffff;
                text-transform: none;
            }
        }
    }
}