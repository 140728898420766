.root {
    display: flex;
    flex-direction: column;

    .dropContainer {
        border: 1px dashed rgba(#000000, 0.12);
        padding: 0px 10px;
    }

    .attachmentsUploadingContainer {        
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 24px;
        margin-bottom: 4px;

        .attachmentsUploadingText {
            color: #000000;
            font-size: 11px;
            font-weight: 300;
        }
    }
}