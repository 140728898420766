.root {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sliderContainer {
        width: 100%;        
        overflow: hidden;

        .imageContainer1 {
            width: 100%;

            .landingPageImage {
                width: 100%;
                object-fit: fill;
            }
        }
    }

    .comingSoonContainer {
        padding: 13px 12px 16px 12px;
        align-items: center;
        text-align: center;

        .comingSoonText {
            font-size: 48px;
            font-weight: 300;
            color: #1D3053;    
        }
    }

    .horizontalDivider {
        background-color: #f8f8f8;
        margin: 16px 10% 39px 10%;
    }

    .whoAreWeContainer1 {
        width: 100%;
        background-color: #f5f4f2;

        .whoAreWeContainer2 {
            display: grid;
            grid-template-columns: minmax(300px, 30%) minmax(400px, 70%);
            margin: 40px 96px 40px 96px;

            .whoAreWeImageContainer {
                width: 100%;
    
                .whoAreWeImage {
                    width: 100%;
                    object-fit: fill;
                }
            }
    
            .storyContainer {
                display: flex;
                flex-direction: column;
                line-height: 30px;                
                margin-left: 126px;
    
                .header {
                    font-size: 22px;
                    font-weight: 200;
                    margin-top: 16px;
                    margin-bottom: 32px;
                    color: #1D3053;
                }
    
                .content {
                    font-size: 16px;
                    font-weight: 300;
                    margin-right: 80px;
                    margin-bottom: 32px;
                    color: rgba(#1D3053, 0.78);

                    li {
                        margin-bottom: 8px;
                    }
                }

                .contentNoSpace {
                    font-size: 16px;
                    font-weight: 300;
                    margin-right: 80px;
                    color: rgba(#1D3053, 0.78);
                }

                .buttonContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    width: 100%;

                    .learnMoreButton {
                        border: 1px solid #778398;

                        .learnMoreText {
                            color: #1D3053;
                            font-size: 14px;
                            font-weight: 300;
                            text-transform: none;
                        }
                    }
                }
            }
        }
    }

    .moreInfoContainer {
        display: grid;
        // grid-template-columns: minmax(300px, 40%) 60%;

        .sellingProcessContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background-color: #FAF8F4;

            .sellingProcessTextContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;                
                
                .sellingProcessText {
                    font-size: 22px;
                    font-weight: 200;
                    color: #1D3053;
                    text-transform: uppercase;
                    margin: 54px 16px 16px 48px;
                }
            }

            .sellingProcessImage {
                width: 100%;
            }
        }

        .signupContainer {
            position: relative;
            width: 100%;
            background-color: white;

            .backgroundContainer {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .emailContainer {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .titleContainer {
                    margin: 40px 66px;
                    
                    .titleText {
                        font-size: 28px;
                        font-weight: 200;
                        color: #1D3053;
                        text-transform: uppercase;
                    }

                    .contentText1 {
                        font-size: 16px;
                        font-weight: 300;
                        color: #1D3053;
                        text-transform: none;
                        margin-top: 40px;
                    }

                    .formContainer {
                        margin-top: 14px;
                        margin-left: -12px;
                        width: 330px;
                        height: 400px;
                        border: 0;
                    }

                    .emailAddressContainer {
                        display: grid;
                        grid-template-columns: 1fr auto;
                        margin-top: 14px;

                        .emailInput {
                            grid-column: 1;
        
                            :global(.MuiFormLabel-root) {
                                font-size: 16px;
                                font-weight: 300;
                                color: rgba(#1D3053, 0.78);
                            }
        
                            :global(.MuiInputBase-root) {
                                font-size: 16px;
                                font-weight: 300;
                                color: rgba(#1D3053, 0.78);
                            }
                        }

                        .notifyMeButtonContainer {
                            grid-column: 2;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            margin-left: 16px;

                            .notifyMeButton {
                                background-color: #1d3053;
                                border-radius: 2px;
                                text-transform: none;
                                padding: 0;
        
                                .notifyMeText {
                                    font-size: 13px;
                                    font-weight: 300;
                                    font-style: light;
                                    color: #ffffff;
                                    margin: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}