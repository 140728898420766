.root {
    .endTimeContainer {
        align-items: center;
        justify-content: center;
        color: #e02f29;

        .timerIcon {
            width: 13px;
            height: 13px;
            margin-right: 5px;
        }

        .auctionEndValue {
            font-size: 13px;
            font-weight: 300;
            color: #e02f29;
            text-align: center;
        }    
    }

    .endTimeContainerLarge {
        align-items: center;
        justify-content: center;
        color: #e02f29;

        .timerIcon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }

        .auctionEndValue {
            font-size: 14px;
            font-weight: 300;
            color: #e02f29;
            text-align: center;
        }    
    }

    .endDaysContainer {
        align-items: center;
        justify-content: center;

        .endDaysValue {
            font-size: 13px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }

    .endDateContainer {
        align-items: center;
        justify-content: center;

        .auctionEndValue {
            font-size: 13px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }

    .endDateContainerLarge {
        align-items: center;
        justify-content: center;

        .auctionEndValue {
            font-size: 14px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }
}

.rootMobile {
    .endTimeContainer {
        align-items: center;
        justify-content: center;
        color: #e02f29;

        .timerIcon {
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }

        .auctionEndValue {
            font-size: 12px;
            font-weight: 300;
            color: #e02f29;
            text-align: center;
        }    
    }

    .endTimeContainerLarge {
        align-items: center;
        justify-content: center;
        color: #e02f29;

        .timerIcon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }

        .auctionEndValue {
            font-size: 14px;
            font-weight: 300;
            color: #e02f29;
            text-align: center;
        }    
    }

    .endDaysContainer {
        align-items: center;
        justify-content: center;

        .endDaysValue {
            font-size: 12px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }

    .endDateContainer {
        align-items: center;
        justify-content: center;

        .auctionEndValue {
            font-size: 12px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }

    .endDateContainerLarge {
        align-items: center;
        justify-content: center;

        .auctionEndValue {
            font-size: 14px;
            font-weight: 300;
            color: #1d3053;
            text-align: center;
        }
    }
}