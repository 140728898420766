.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-items: center;

    .dropContainer {
        display: flex;
        flex-direction: column;    
        align-items: center;
        justify-items: center;
    
        .uploadIconContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #D5D8DE;
            margin-top: 18px;
            margin-bottom: 8px;

            .uploadIcon {
                width: 18px;
                height: auto;
                color: #212F51;
            }
        }

        .infoContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 18px;

            .infoText {
                color: rgba(#000000, 0.87);
                font-size: 13px;
                font-weight: 300;
                margin-top: 6px;
            }

            a {
                color: #212F51;
                text-decoration-color: #DCDEE3;
            }
        }

        .errorContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 18px;

            .errorText {
                color: red;
                font-size: 13px;
                font-weight: 400;                
            }
        }
    }
}