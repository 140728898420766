.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .backButtonContainer {
        display: flex;
        flex-direction: row;
        width: 100%;        

        .backButton {
            display: flex;
            flex-direction: row;
            justify-items: center;
            background-color: white;
            border: none;
            border-radius: 2;
            padding: 8px;
            margin: 22px 24px 9px 24px;

            .backIcon {
                color: #1d3053;
                font-size: 18px;
            }

            .backLabel {
                font-size: 13px;
                font-weight: 300;
                color: #1d3053;
                text-transform: none;
            }
        }

        .backButton:hover {
            background-color: #EDEEF1;
        }
    }

    .loadingContainer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        .loader {
            width: 32px;
            height: 32px;
            border: 5px solid #CDB079;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .loadingLabel {
            margin-left: 16px;
            font-size: 32px;
            font-weight: 300;
            color: #1d3053;            
        }
    }

    .noInfoContainer {
        padding: 8px 32px 32px 32px;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        height: 100%;

        .noInfoLabel {
            font-size: 32px;
            font-weight: 300;
            color: #1d3053;            
        }
    }

    .contentContainer {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;        

        .leftContentContainer {
            padding: 8px 16px 32px 32px;

            .imagesContainer {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 0;
                min-height: 100%;
                overflow: hidden;

                .watchImage {
                    width: 100%;
                }

                .imageOverlayLink {
                    display: inline-block;
                    position: relative;
                }
        
                .overlayIcon {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 6px;
                    fill: rgba(#FFFFFF, 0.6);
                }        
            }

            .watchNoImageContainer {
                width: 100%;
                margin-top: 64px;
                justify-content: center;
                align-items: center;

                & svg {
                    color: #B5B5B5;
                }

                .watchNoImage {
                    width: 150px;
                }

                .watchNoImageText {
                    color: #B5B5B5;
                }
            }
        }

        .rightContentContainer {
            display: flex;
            flex-direction: column;
            padding: 8px 32px 32px 16px;
        }
    }

    .lightGalleryContainer {
        display: none;
    }

    .mobileImagesContainer {
        width: 100%;
        position: relative;

        .imageContainer {
            width: 100%;
        }

        .imageOverlayLink {
            display: inline-block;
            position: relative;
        }

        .overlayIcon {
            position: absolute;
            right: 0;
            top: 0;
            margin: 6px;
            fill: rgba(#FFFFFF, 0.6);
        }

        .image {
            width: 100%;
            object-fit: fill;
        }
    }

    .mobileSliderDots {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 6px;

        .sliderDot {
            width: 10px;
            height: 10px;
            color: rgb(29, 48, 83);
            stroke: rgb(29, 48, 83);
            margin: 0 1px;
        }
    }

    .mobileItemDetailsContainer {
        margin: 16px 16px 64px 16px;
    }
}