.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContainer1 {
        width: 100%;
        overflow: hidden;

        .faqImage {
            width: 100%;
            object-fit: fill;
        }
    }

    .titleContainer {
        width: 100%;

        .titleText {
            font-size: 28px;
            font-weight: 200;
            color: #1D3053;
            text-transform: uppercase;
            margin: 17px 32px 0 32px;
        }
    }

    .searchContainerMobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .haveQuestionsText {
            font-size: 13px;
            font-weight: 300;
            color: rgba(#1D3053, 0.78);
            margin: 16px 32px 12px 32px;
        }

        .searchInputContainer {
            width: 100%;
            margin-bottom: 32px;

            :global(.MuiFormControl-fullWidth) {
                margin-left: 32px;
                width: calc(100% - 64px);
            }
        
            .searchInput {

                :global(.MuiFormLabel-root) {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(#1D3053, 0.78);
                }

                :global(.MuiInputBase-root) {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(#1D3053, 0.78);
                }
            }
        }        
    }

    .searchContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .haveQuestionsText {
            font-size: 13px;
            font-weight: 300;
            color: rgba(#1D3053, 0.78);
            margin: 14px 32px 16px 32px;
        }

        .searchInputContainer {
            width: 200px;
            margin-top: -21px;
            margin-right: 32px;

            .searchInput {
                :global(.MuiFormLabel-root) {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(#1D3053, 0.78);
                }

                :global(.MuiInputBase-root) {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(#1D3053, 0.78);
                }
            }
        }
    }

    .buyerSellerContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1px;
        margin-bottom: 38px;

        .buyerSellerText {
            font-size: 20px;
            font-weight: 200;
            color: #000000;
            margin-right: 17px;
        }

        .buyerContainer {
            display: flex;
            flex-direction: row;

            .buyerButton {
                border: 1px solid rgba(#021A33, 0.05);
                border-radius: 3px;
                box-shadow: 0px 1px 0px 0px rgba(#021A33, 0.1);
                background-color: white;
                z-index: 1;

                .buyerText {
                    font-size: 16px;
                    font-weight: 300;
                    color: #141414;
                    margin: 4px 12px;
                    text-transform: none;
                }
            }

            .sellerButton {
                border-radius: 3px;
                background-color: rgba(#003266, 0.08);
                z-index: 0;
                margin-left: -3px;
                cursor: pointer;

                .sellerText {
                    font-size: 16px;
                    font-weight: 300;
                    color: #141414;
                    margin: 5px 13px 5px 15px;
                    text-transform: none;
                }
            }

            .sellerButton:hover {
                background-color: #BCD9F5;
            }
        }

        .sellerContainer {
            display: flex;
            flex-direction: row;

            .buyerButton {
                border-radius: 3px;
                background-color: rgba(#003266, 0.08);
                z-index: 0;
                cursor: pointer;

                .buyerText {
                    font-size: 16px;
                    font-weight: 300;
                    color: #141414;
                    margin: 5px 15px 5px 13px;
                    text-transform: none;
                }
            }

            .buyerButton:hover {
                background-color: #BCD9F5;
            }
            
            .sellerButton {
                border: 1px solid rgba(#021A33, 0.05);
                border-radius: 3px;
                box-shadow: 0px 1px 0px 0px rgba(#021A33, 0.1);
                background-color: white;
                margin-left: -3px;
                z-index: 1;

                .sellerText {
                    font-size: 16px;
                    font-weight: 300;
                    color: #141414;
                    margin: 4px 12px;
                    text-transform: none;
                }
            }
        }
    }

    .faqContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
    }

    .faqContainerMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .faqItems {
            margin: 0 32px 0 32px;
        }
    }
}