.root {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;

    .leftContainer {
        padding-top: 16px;
        padding-right: 32px;

        .menuOption {
            align-items: center;
            padding: 8px 16px;
        }

        .menuOptionSelected {
            align-items: center;
            padding: 8px 16px;
            background-color: rgba(#A5ACBA, 0.4);
        }

        .menuLink {
            color: rgba(#000000, 0.87);
            font-size: 13px;
            font-weight: 300;

            a {
                text-decoration: none;
                color: rgba(#000000, 0.87);
            }    
        }

        .awaitingContainer {
            justify-content: center;
            color: white;
            background-color: #A38C5E;
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .pendingContainer {
            justify-content: center;
            color: white;
            background-color: #A0570D;
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .liveContainer {
            justify-content: center;
            color: white;
            background-color: #0F7F5A;
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .inactiveContainer {
            justify-content: center;
            color: white;
            background-color: rgba(#000000, 0.7);
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .countLabel {
            font-size: 10px;
            font-weight: 300;
        }        
    }

    .noItemsContainer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-items: center;

        .noItemsLabel {
            font-size: 13px;
            margin-top: 20px;
        }
    }
}

.rootMobile {
    .statusFilterContainerMobile {
        :global(.MuiInputBase-root::before) {
            border-bottom-width: 0 !important;
        }

        :global(.MuiInputBase-root::after) {
            border-bottom-width: 0 !important;
        }
    }

    .noItemsContainer {
        margin: 16px 8px;

        .noItemsLabel {
            font-size: 13px;
        }
    }    
}

.statusText {
    font-size: 13px !important;
    font-weight: 300 !important;
    color: #1d3053 !important;      
}

.statusDropdown {
    font-size: 13px;
    font-weight: 300;
    color: #1d3053;
}

.statusDropdownSelected {
    background-color: white;
}

.awaitingContainerMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #A38C5E;
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}

.pendingContainerMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #A0570D;
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}

.liveContainerMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #0F7F5A;
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}

.inactiveContainerMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(#000000, 0.7);
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}
