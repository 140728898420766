.root {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.rootMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.leftContainer {
    margin-right: 20px;
}

.rightContainer {
    margin-left: 20px;
}

.nameContainer {
    display: grid;
    grid-template-columns: auto 1fr;

    .textContainer {
        align-items: flex-end;

        .welcomeText {
            font-size: 48px;
            font-weight: 200;
            color: #1D3053;
            margin-left: 12px;
        }

        .nameText {
            font-size: 32px;
            font-weight: 400;
            color: #A38C5E;
            text-transform: capitalize
        }

        .divider {
            margin-top: 28px;
            width: 100%;
            border-bottom: 1px solid rgba(#000000, 0.12);
        }
    }
}

.nameContainerMobile {
    display: grid;
    grid-template-columns: auto 1fr;

    .textContainer {
        align-items: flex-end;

        .welcomeText {
            font-size: 32px;
            font-weight: 200;
            color: #1D3053;
            margin-left: 12px;
        }

        .nameText {
            font-size: 18px;
            font-weight: 400;
            color: #A38C5E;
            text-transform: capitalize
        }

        .divider {
            margin-top: 14px;
            width: 100%;
            border-bottom: 1px solid rgba(#000000, 0.12);
        }
    }
}

.manageContainer {
    margin-top: 16px;
    justify-content: flex-end;

    .manageButton {
        padding: 8px;
        background-color: #1D3053;
        border-radius: 2px;

        .manageText {
            color: white;
            font-size: 13px;
            font-weight: 300;
            text-transform: none;
        }
    }
}

.verifiedContainer {
    margin-top: 16px;

    .verifiedText {
        color: #1D3053;
        font-size: 14px;
        font-weight: 200;
    }
}

.cardDetailsContainerMobile {
    margin-top: 32px;
}

.accountSettingsContainer {
    .header {
        margin-top: 63px;
        font-size: 22px;
        font-weight: 200;
        color: rgba(#000000, 0.7);
    }
    
    .inputField {
        margin-top: 16px;
    
        :global(.MuiFormLabel-root) {
            color: #1D3053;
            font-size: 14px;
            font-weight: 200;
        }
    
        :global(.MuiInputBase-root) {
            color: #1D3053;
            font-size: 14px;
            font-weight: 200;
        }
    }    
}

.accountSettingsContainerMobile {
    .header {
        margin-top: 32px;
        font-size: 18px;
        font-weight: 200;
        color: rgba(#000000, 0.7);
    }
    
    .inputField {
        margin-top: 16px;
    
        :global(.MuiFormLabel-root) {
            color: #1D3053;
            font-size: 14px;
            font-weight: 200;
        }
    
        :global(.MuiInputBase-root) {
            color: #1D3053;
            font-size: 14px;
            font-weight: 200;
        }
    }    
}