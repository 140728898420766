.snackbar {
    :global(.MuiPaper-root) {
        :global(.MuiButtonBase-root) {
            color: #111;
        }
    }
}

.alertContainer {
    :global(.MuiAlert-message) {
        padding: 6px 0 10px 0;
    }
}