.root {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 8px;

    .imageContainer {
        width: 64px;
        height: 50px;
        margin-left: 12px;
        margin-right: 12px;

        .loadedImage {
            width: 100%;
            height: 100%;
        }
    }

    .fileInfoContainer {
        display: flex;
        flex-direction: column;
        width: 100%;        

        .fileNameText {
            margin-top: 6px;
            color: #212F51;
            font-size: 14px;
            font-weight: 400;
        }

        .fileSizeText {
            margin-top: 3px;
            color: rgba(#000000, 0.54);
            font-size: 11px;
            font-weight: 400;
        }

        .progressBarContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 12px;

            .progressBar {
                display: flex;
                flex-direction: row;
                width: 100%;

                .doneBar {
                    height: 3px;
                    background-color: #CCAF76;
                }

                .remainingBar {
                    height: 3px;
                    background-color: rgba(#CCAF76, 0.6);
                }
            }
        }
    }

    .clearButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;        
    }
}