.root {
    .watchBrandInput {
        color: #000;

        :global(.MuiFormLabel-root) {
            color: #4d5b77;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.5px;
        }

        :global(.MuiInputBase-root) {
            border: 1px #000;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #000;
        }
    }
}