.root {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;

    .leftContainer {
        padding-top: 16px;
        padding-right: 32px;

        .menuOption {
            align-items: center;
            padding: 8px 16px;
            display: flex;
            justify-content: flex-start;
            border-radius: 0 !important;
        }

        .menuOptionSelected {
            align-items: center;
            padding: 8px 16px;
            background-color: rgba(#a5acba, 0.4);
            border-radius: 0 !important;
            display: flex;
            justify-content: flex-start;
        }

        .menuLink {
            color: rgba(#000000, 0.87);
            font-size: 13px;
            font-weight: 300;

            a {
                text-decoration: none;
                color: rgba(#000000, 0.87);
            }
        }

        .activeContainer {
            justify-content: center;
            color: white;
            background-color: #a38c5e;
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .inactiveContainer {
            justify-content: center;
            color: white;
            background-color: rgba(#000000, 0.7);
            padding: 0 5px 0 4px;
            border-radius: 4px;
            margin-left: 8px;
        }

        .countLabel {
            font-size: 10px;
            font-weight: 300;
        }
    }

    .itemsContainer {
        flex-wrap: wrap;
        row-gap: 32px;
        column-gap: 28px;
        padding-top: 16px;
        padding-right: 32px;
    }
}

.rootMobile {
    .statusFilterContainerMobile {
        :global(.MuiInputBase-root::before) {
            border-bottom-width: 0 !important;
        }

        :global(.MuiInputBase-root::after) {
            border-bottom-width: 0 !important;
        }
    }
}

.statusText {
    font-size: 13px !important;
    font-weight: 300 !important;
    color: #1d3053 !important;
}

.menuOptionMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(#000000, 0.7);
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}

.menuOptionSelectedMobile {
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #a38c5e;
    padding: 0 5px 0 4px;
    border-radius: 4px;
    margin-left: 8px;

    .countLabelMobile {
        font-size: 12px;
        font-weight: 300;
        margin: 0 3px;
    }
}

.itemsContainerMobile {
    margin: 6px 4px 28px 4px;

    .itemsContainerInner {
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 24px;
    }
}

.statusDropdown {
    font-size: 13px;
    font-weight: 300;
    color: #1d3053;
}

.statusDropdownSelected {
    background-color: white;
}