.root {
    width: 100%;
    display: flex;
    flex-direction: column;

    .imageContainer1 {
        width: 100%;

        .landingPageImage {
            width: 100%;
            object-fit: fill;
        }
    }

    .comingSoonContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .comingSoonText {
            font-size: 24px;
            font-weight: 200;
            color: #212F51;
            margin: 18px;
        }
    }

    .horizontalDivider {
        background-color: #f8f8f8;
        margin: 0 10% 15px 10%;
    }

    .whoAreWeContainer {
        width: 100%;
        background-color: #f5f4f2;

        .storyContainer {
            display: flex;
            flex-direction: column;
            margin: 56px 32px 24px 32px;

            .headerTop {
                font-size: 22px;
                font-weight: 200;
                margin-top: 16px;
                color: #1D3053;
            }

            .headerBottom {
                font-size: 22px;
                font-weight: 200;
                margin-bottom: 32px;
                color: #1D3053;
            }

            .content {
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 32px;
                color: rgba(#1D3053, 0.78);

                li {
                    margin-bottom: 8px;
                }
            }

            .contentNoSpace {
                font-size: 16px;
                font-weight: 300;
                margin-right: 80px;
                color: rgba(#1D3053, 0.78);
            }

            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                margin-bottom: 24px;

                .learnMoreButton {
                    border: 1px solid #778398;

                    .learnMoreText {
                        color: #1D3053;
                        font-size: 14px;
                        font-weight: 300;
                        text-transform: none;
                    }
                }
            }
        }

        .whoAreWeImageContainer {
            width: 100%;

            .whoAreWeImage {
                width: 100%;
                object-fit: fill;
            }
        }
    }

    .signupContainer {
        position: relative;
        width: 100%;
        min-height: 600px;
        background-color: white;

        .backgroundContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .emailContainer {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .titleContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 32px;
                
                .titleText {
                    font-size: 28px;
                    font-weight: 200;
                    color: #1D3053;
                    text-transform: uppercase;
                }

                .contentText1 {
                    font-size: 16px;
                    font-weight: 300;
                    color: #1D3053;
                    text-transform: none;
                    margin-top: 40px;
                }

                .formContainer {
                    margin-top: 14px;
                    margin-left: -10px;
                    width: 100%;
                    height: 400px;
                    border: 0;
                }
            }
        }
    }    

    .sellingProcessContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #FAF8F4;

        .sellingProcessTextContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;                
            
            .sellingProcessText {
                font-size: 22px;
                font-weight: 200;
                color: #1D3053;
                text-transform: uppercase;
                margin: 40px 32px 0 32px;
            }
        }

        .sellingProcessImageContainer {

            .sellingProcessImage {
                width: 100%;
            }
        }
    }
}