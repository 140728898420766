.root {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    border: 1px solid #CAC4D0;
    border-radius: 3px;
    margin-top: 16px;

    .inputRequired {
        color: red !important;
    }

    .editable {
        :global(.MuiInputBase-input) {
            padding: 4px 8px !important;
        }

        :global(.MuiOutlinedInput-input) {
            font-size: 13px;
            font-weight: 300;
            color: #1d3053;
        }
    }

    .editableMultiline {
        min-height: 80px;
        margin-top: 8px;

        :global(.MuiInputBase-root) {
            padding: 0 !important;
        }

        :global(.MuiInputBase-input) {
            padding: 4px 8px !important;
        }

        :global(.MuiOutlinedInput-input) {
            font-size: 13px;
            font-weight: 300;
            color: #1d3053;
        }
    }

    .editableDropdown {
        font-size: 13px !important;
        font-weight: 300;
        color: #1d3053;

        :global(.MuiInputBase-input) {
            padding: 4px 8px !important;
        }
    }

    :global(.Mui-disabled) {
        background-color: #BABEC7 !important;
    }

    .actionButtonStrong {
       
        padding: 8px;
        background-color: #1D3053;
        border-radius: 2px;
        margin-top: 8px;

        .actionButtonText {
            color: white;
            font-size: 13px;
            font-weight: 300;
            text-transform: none;
        }
    }

    .picturesContainer {
        padding: 16px;

        .mainImageContainer {
            border-radius: 4px;

            .imageOverlayLink {
                display: inline-block;
                position: relative;
            }

            .mainImage {
                border-radius: 4px;
                width: 208px;
            }

            .overlayIcon {
                position: absolute;
                left: 0;
                top: 0;
                margin: 6px;
                fill: rgba(black, 0.6);
            }
        }

        .watchNoImageContainer {
            width: 208px;
            height: 184px;
            justify-content: center;
            align-items: center;

            & svg {
                color: #B5B5B5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #B5B5B5;
            }
        }

        .otherImagesContainer {
            display: flex;
            flex-direction: row;
            overflow-x: hidden;
            overflow-y: hidden;
            width: 208px;
            height: 43px;
            margin-top: 8px;
            position: relative;

            .thumbnailImageFirst {
                border-radius: 4px;
                width: 64px;
                height: 43px;
            }

            .thumbnailImage {
                border-radius: 4px;
                margin-left: 8px;
                width: 64px;
                height: 43px;
            }

            .moreContainer {
                border-radius: 4px;
                background-color: rgba(#000000, 0.57);
                width: 64px;
                height: 43px;
                position: absolute;
                left: 144px;
                display: flex;
                justify-content: center;
                align-items: center;

                .moreText {
                    color: white;
                    font-size: 28px;
                    font-weight: 400;
                }
            }
        }
    }

    .detailsContainer {
        margin: 16px 24px;

        .brandText {
            color: #1D3053;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
        }

        .modelText {
            margin-top: 8px;
            color: #000000;
            font-size: 16px;
            font-weight: 200;
            text-transform: uppercase;
        }

        .yearText {
            margin-top: 8px;
            margin-bottom: 16px;
            color: rgba(#000000, 0.7);
            font-size: 13px;
            font-weight: 400;
        }

        .summaryText {
            font-size: 13px;
            font-weight: 200;
            color: #1d3053;

            ul {
                list-style: none;

                li::before {
                    content: "\2022";
                    color: #D2AE6D;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }

        .summarySpacer {
            height: 12px;
        }

        .specsContainer {
            margin-top: 32px;
            flex-wrap: wrap;
            row-gap: 16px;
            column-gap: 40px;

            .specTitle {
                color: rgba(#1D3053, 0.78);
                font-size: 10px;
                font-weight: 400;
                text-transform: uppercase;
            }

            .specText {
                color: #1D3053;
                font-size: 13px;
                font-weight: 300;
            }
        }

        .conditionButton {      
            display: flex;
            flex-direction: row;
            cursor: pointer;
            margin: 20px 8px 0 8px;
            align-items: center;

            .conditionButtonText {
                color: #1D3053;
                font-size: 10px;
                font-weight: 400;
            }

            .conditionButtonImage {
                margin-left: 4px;
                font-size: 15px;
            }
        }

        .conditionContentContainer {
        
            .otherImagesContainer {
                display: flex;
                flex-direction: row;
                overflow-x: hidden;
                overflow-y: hidden;
                width: 208px;
                height: 43px;
                margin: 8px 13px 0 13px;
                position: relative;
    
                .thumbnailImageFirst {
                    border-radius: 4px;
                    width: 64px;
                    height: 43px;
                }
    
                .thumbnailImage {
                    border-radius: 4px;
                    margin-left: 8px;
                    width: 64px;
                    height: 43px;
                }
    
                .moreContainer {
                    border-radius: 4px;
                    background-color: rgba(#000000, 0.57);
                    width: 64px;
                    height: 43px;
                    position: absolute;
                    left: 144px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .moreText {
                        color: white;
                        font-size: 28px;
                        font-weight: 400;
                    }
                }
            }

            .conditionText {
                margin: 8px 13px 0 13px;
                color: #1D3053;
                font-size: 11px;
                font-weight: 300;
            }

            .conditionNoInfo {
                margin: 8px 13px 0 13px;
                font-size: 11px;
                font-weight: 300;
                color: rgba(#000000, 0.7);
            }    
        }
    }

    .divider {
        display: flex;
        flex-direction: row;
        align-items: center;        
        height: 100%;
        margin-right: 22px;

        .dividerItem {
            height: calc(100% - 32px);
            min-width: 1px;
            width: 1px;
            background-color: rgba(#000000, 0.42);
        }
    }

    .detailsRightContainer {

        .listingInfoContainer {
            margin: 16px 16px 0 0;

            .statusText {
                color: rgba(#1D3053, 0.78);
                font-size: 13px;
                font-weight: 300;
            }

            .statusValue {
                color: #1D3053;
                font-size: 18px;
                font-weight: 300;
            }

            .listingInfoText {
                color: rgba(#1D3053, 0.78);
                font-size: 10px;
                font-weight: 400;
            }

            .listingInfoValue {
                color: #1D3053;
                font-size: 13px;
                font-weight: 300;
            }

            .dateInput {
                :global(.MuiOutlinedInput-input) {
                    padding: 3px 6px;
                }

                :global(.MuiButtonBase-root) {
                    padding: 0;
                }
            }
        }

        .listingInfoBottomContainer {
            margin: 8px 16px 16px 0;

            .auctionEndsContainer {
                align-items: center;

                .auctionEndsIcon {
                    font-size: 16px;
                    color: #BC251B;
                }

                .auctionEndsText {
                    color: #BC251B;
                    font-size: 13px;
                    font-weight: 300;
                    margin-left: 2px;
                }
            }

            .contractContainer {
                align-items: center;
                margin-top: 8px;

                .contractIcon {
                    font-size: 16px;
                    color: #1D3053;
                }

                .contractText {
                    color: #1D3053;
                    font-size: 13px;
                    font-weight: 300;

                    a {
                        color: #1D3053;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .actionButton {
        margin-top: 4px;
        padding: 8px;
        background-color: #1D3053;
        border-radius: 2px;
        min-width: 150px;

        .actionText {
            color: white;
            font-size: 13px;
            font-weight: 300;
            text-transform: none;
        }
    }
}