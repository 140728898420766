.root {
    display: flex;
    flex-direction: row;

    .navButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        color: rgba(black, 0.87);
        padding: 0;
        margin: 0 4px;

        .statusLabel {
            margin: 5px 12px;
            font-weight: 300;
            font-size: 16px;
            text-transform: none;
        }
    }

    .navSelected {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none !important;
        border-radius: 4px !important;
        background-color: #172642 !important;
        color: white !important;
        padding: 0;
        margin: 0 4px;

        .statusLabel {
            margin: 5px 12px;
            font-weight: 300;
            font-size: 16px;
            text-transform: none;
        }
    }

    .chevronIcon {
        fill: rgba(#000000, 0.38);
        font-size: 24px;
        font-weight: 400;
    }
}