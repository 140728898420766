.copyrightTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #212f51;
    width: 100%;

    .copyrightText {
        color: white;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        margin: 8px;
    }
}
