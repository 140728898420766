@font-face {
    font-family: "Helvetica Neue";   /*Can be any text*/
    src: local("helveticaneue-light"),
      url("./app/fonts/helveticaneue-light.otf") format("opentype");
}

html, body, #root {
    height: 100%
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}