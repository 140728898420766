.root {
    width: 100%;
    height: 100%;

    .label {
        margin-right: 6px;
        color: #1D3053;
        font-size: 14px
    }

    .reportTypeContainer {
        align-items: center;

        .reportTypeDropdown {
            font-size: 13px !important;
            font-weight: 300;
            color: #1d3053;

            :global(.MuiInputBase-input) {
                padding: 4px 32px 4px 8px !important;
            }
        }

        .generateReportButton {
            margin-left: 8px;
            background-color: white;
            border: 1px solid #778398;
            border-radius: 2px;
            padding: 3px 6px;
        }

        .generateReportText {
            font-size: 13px;
            font-weight: 300;
            color: #1D3053;
            text-transform: none;
        }
    }

    .reportDescriptionContainer {
        align-items: center;
        margin-top: 8px;

        .description {
            color: #1D3053;
            font-size: 14px    
        }
    }

    .gridContainer {
        margin-top: 16px;
        width: 100%;
        height: 100%;

        .grid {
            min-height: 300px;
        }
    }
}