.root {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .loadingContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 48px;

        .loadingText {
            font-size: 18px;
            font-weight: 300;
            color: #1D3053;
            margin-left: 12px;
        }

        .loader {
            width: 32px;
            height: 32px;
            border: 5px solid #CDB079;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .bidConfirmedContainer {
        width: 100%;
        margin-top: 32px;
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 36px;
            font-weight: 400;
            color: #4D5B77;
        }

        .bidConfirmedText {
            font-size: 36px;
            font-weight: 400;
            color: #1F7A5C;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: min(70vw, 552px);
    }

    .descriptionText {
        font-size: 16px;
        font-weight: 300;
        color: #1D3053;
    }

    .watchImageContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;

        .watchImage {
            width: 380px;
        }

        .watchNoImageContainer {
            width: 250px;
            height: 250px;                
            justify-content: center;
            align-items: center;

            & svg {
                color: #B5B5B5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #B5B5B5;
            }
        }
    }

    .conditionsContainer {
        margin-top: 8px;
        width: 100%;
        align-items: center;

        .iconContainer {
            color: #A28957;
            background-color: #F5F5F5;
            border-radius: 4px;
            margin-right: 32px;
            align-items: center;
            justify-items: center;

            .icon {
                width: 40px;
                height: 40px;
                padding: 8px;
            }
        }
    }

    .acceptConditionsContainer {
        margin-top: 18px;
        margin-bottom: 22px;
        width: 100%;
    }

    .dividerContainer {
        margin-left: 16px;
        margin-right: 16px;
        align-items: center;
        justify-items: center;
        margin-bottom: 12px;

        .divider {
            width: 100%;
            height: 1px;
            background-color: #EBEBEB;
        }
    }

    .bidContainer {
        width: 100%;

        .yourBidContainer {
            margin-left: 16px;

            .bidTitle {
                font-size: 18px;
                font-weight: 400;
                color: #4D5B77;
            }

            .bidValue {
                font-size: 36px;
                font-weight: 400;
                color: #1D3053;
            }
        }

        .spacer {
            flex-grow: 1;
        }

        .confirmContainer {
            align-items: flex-end;
            justify-items: flex-end;
            margin-right: 16px;

            :global(.Mui-disabled) {
                background-color: #BABEC7 !important;
            }

            .confirmButton {
                background-color: #172642;
                border-radius: 8px;
                padding: 10px 24px;
                margin-bottom: 12px;
            }
    
            .confirmText {
                font-size: 16px;
                font-weight: 300;
                color: #ffffff;
                text-transform: none;
            }
        }
    }
}

.rootMobile {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .loadingContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 16px;

        .loadingText {
            font-size: 12px;
            font-weight: 300;
            color: #1D3053;
            margin-left: 8px;
        }

        .loader {
            width: 18px;
            height: 18px;
            border: 5px solid #CDB079;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .bidConfirmedContainer {
        width: 100%;
        margin-top: 16px;
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 18px;
            font-weight: 400;
            color: #4D5B77;
        }

        .bidConfirmedText {
            font-size: 18px;
            font-weight: 400;
            color: #1F7A5C;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: 70vw;
    }

    .descriptionText {
        font-size: 11px;
        font-weight: 300;
        color: #1D3053;
    }

    .watchImageContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 8px;

        .watchImage {
            width: 250px;
        }

        .watchNoImageContainer {
            width: 250px;
            height: 250px;                
            justify-content: center;
            align-items: center;

            & svg {
                color: #B5B5B5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #B5B5B5;
            }
        }
    }

    .conditionsContainer {
        margin-top: 8px;
        width: 100%;
        align-items: center;

        .iconContainer {
            color: #A28957;
            background-color: #F5F5F5;
            border-radius: 4px;
            margin-right: 8px;
            align-items: center;
            justify-items: center;

            .icon {
                width: 20px;
                height: 20px;
                padding: 4px;
            }
        }
    }

    .acceptConditionsContainer {
        margin-top: 8px;
        margin-bottom: 11px;
        width: 100%;
    }

    .dividerContainer {
        margin-left: 8px;
        margin-right: 8px;
        align-items: center;
        justify-items: center;
        margin-bottom: 6px;

        .divider {
            width: 100%;
            height: 1px;
            background-color: #EBEBEB;
        }
    }

    .bidContainer {
        width: 100%;

        .yourBidContainer {
            margin-left: 8px;

            .bidTitle {
                font-size: 12px;
                font-weight: 400;
                color: #4D5B77;
            }

            .bidValue {
                font-size: 18px;
                font-weight: 400;
                color: #1D3053;
            }
        }

        .spacer {
            flex-grow: 1;
        }

        .confirmContainer {
            align-items: flex-end;
            justify-items: flex-end;
            margin-right: 8px;

            :global(.Mui-disabled) {
                background-color: #BABEC7 !important;
            }

            .confirmButton {
                background-color: #172642;
                border-radius: 8px;
                padding: 5px 12px;
                margin-bottom: 6px;
            }
    
            .confirmText {
                font-size: 11px;
                font-weight: 300;
                color: #ffffff;
                text-transform: none;
            }
        }
    }
}