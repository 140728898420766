.root {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 24px;
            font-weight: 400;
            color: #4D5B77;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: calc(100% - 50px);
    }    
}

.rootMobile {
    :global(.MuiPaper-root) {
        border-radius: 8px;
    }

    .dialogTitleContainer {
        padding-bottom: 12px;

        .titleText {
            font-size: 14px;
            font-weight: 400;
            color: #4D5B77;
        }

        .closeIcon {
            width: 18px;
            height: 18px;
        }
    }

    .contentContainer {
        width: calc(100% - 50px);
    }
}