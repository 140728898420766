.root {
    background-color: rgba(#000000, 0.07);
    border-radius: 4px;
    width: 100%;
    
    .setupCompleteContainer {
        margin: 12px 12px 0 11px;
        align-items: center;

        .setupIcon {
            font-size: 18px;
            color: green;
            margin-right: 4px;
        }

        .setupText {
            font-size: 16px;
            font-weight: 300;
            color: green;
        }
    }

    .setupRequiredContainer {
        margin: 12px 12px 0 11px;
        align-items: center;

        .setupIcon {
            font-size: 18px;
            color: red;
            margin-right: 4px;
        }

        .setupText {
            font-size: 16px;
            font-weight: 300;
            color: red;
        }
    }

    .securityTitle {
        margin: 12px 12px 8px 12px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(#1D3053, 0.78);
    }

    .securityContent {
        margin: 8px 12px;
        font-size: 13px;
        font-weight: 300;
        color: #1D3053;
    }

    .updateCardButtonContainer {
        width: 100%;
        justify-content: flex-end;

        .updateCardButton {
            margin: 24px 12px 12px 12px;
            padding: 4px 8px 2px 8px;
            background-color: transparent;
            border: 1px solid #778398;
            border-radius: 2px;

            .updateCardContentsContainer {
                align-items: center;

                .updateCardIcon {
                    width: 16px;
                    height: 16px;
                }

                .updateCardText {
                    margin-left: 4px;
                    font-size: 13px;
                    font-weight: 300;
                    color: #1D3053;
                    text-transform: none;
                }
            }
        }
    }
}

.rootMobile {
    background-color: rgba(#000000, 0.07);
    border-radius: 4px;
    width: 100%;

    .setupCompleteContainer {
        margin: 12px 12px 0 11px;
        align-items: center;

        .setupIcon {
            font-size: 18px;
            color: green;
            margin-right: 4px;
        }

        .setupText {
            font-size: 16px;
            font-weight: 300;
            color: green;
        }
    }

    .setupRequiredContainer {
        margin: 12px 12px 0 11px;
        align-items: center;

        .setupIcon {
            font-size: 18px;
            color: red;
            margin-right: 4px;
        }

        .setupText {
            font-size: 16px;
            font-weight: 300;
            color: red;
        }
    }

    .securityTitle {
        margin: 12px 12px 8px 12px;
        font-size: 16px;
        font-weight: 300;
        color: rgba(#1D3053, 0.78);
    }

    .securityContent {
        margin: 8px 12px;
        font-size: 13px;
        font-weight: 300;
        color: #1D3053;
    }

    .updateCardButtonContainer {
        width: 100%;
        justify-content: flex-end;

        .updateCardButton {
            margin: 24px 12px 12px 12px;
            padding: 4px 8px 2px 8px;
            background-color: transparent;
            border: 1px solid #778398;
            border-radius: 2px;

            .updateCardContentsContainer {
                align-items: center;

                .updateCardIcon {
                    width: 16px;
                    height: 16px;
                }

                .updateCardText {
                    margin-left: 4px;
                    font-size: 13px;
                    font-weight: 300;
                    color: #1D3053;
                    text-transform: none;
                }
            }
        }
    }
}
