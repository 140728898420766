.root {
    width: 100%;
    display: flex;
    flex-direction: column;

    .stayInTouchContainer1 {
        width: 100%;
        background-color: #f5f4f2;

        .stayInTouchContainer2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 16px;
            color: #212f51;
    
            .header {
                font-size: 18px;
                font-weight: 200;
                text-transform: uppercase;
            }

            .iconsContainer {
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                gap: 35px;
                align-items: center;

                a {
                    text-decoration: none;
                    color: #212f51;
                }

                .emailIcon {
                    width: 34px;
                    height: 34px;
                }

                .phoneIcon {
                    width: 30px;
                    height: 30px;
                }

                .instagramIcon {
                    width: 32px;
                    height: 32px;
                }

                .twitterIcon {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .infoLinksContainer1 {
        width: 100%;
        padding-bottom: 40px;
        background-color: #f5f4f2;

        .infoLinksContainer2 {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(5, minmax(120px, 1fr));

            .infoLinksContainer3 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;    

                .infoLinksContainer4 {
                    display: flex;
                    flex-direction: column;
                    
                    .header1 {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 30px;
                        color: #152850;
                    }

                    .header2 {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 30px;
                        color: #152850;
                        margin-top: 32px;
                    }

                    a {
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 200;
                        line-height: 30px;
                        color: #5c5b59;
                    }
                }
            }
        }
    }

    .copyrightContainer1 {        
        width: 100%;
        color: white;
        background-color: #212f51;

        .copyrightContainer2 {
            margin: 8px 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .linksContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
                font-weight: 300;
                line-height: 30px;
        
                a {
                    text-decoration: none;
                    color: white;
                }
        
                .navSpacer {
                    width: 1px;
                    height: 16px;
                    background-color: white;
                    margin: 0 16px;
                }
            }

            .copyrightText {
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
            }
        }
    }

    .disabledLink {
        font-size: 14px;
        font-weight: 200;
        line-height: 30px;
        color: #5c5b59;
    }

    .disabledTCLink {
        font-size: 12px;
        font-weight: 300;
        line-height: 30px;
        color: white;
    }
}
