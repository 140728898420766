.root {
    width: 100%;
    border: 1px solid #CAC4D0;
    border-radius: 3px;
    margin-top: 16px;
    align-items: center;
    position: relative;

    .statusContainer {
        position: absolute;
        top: 8px;
        right: 8px;

        .statusText {
            color: rgba(#1D3053, 0.78);
            font-size: 13px;
            font-weight: 300;
        }

        .statusValueText {
            color: #1D3053;
            font-size: 18px;
            font-weight: 300;
        }
    }    

    .picturesContainer {
        padding: 8px;

        .mainImageContainer {
            border-radius: 4px;

            .imageOverlayLink {
                display: inline-block;
                position: relative;
            }

            .mainImage {
                border-radius: 4px;
                width: 208px;
            }

            .overlayIcon {
                position: absolute;
                left: 0;
                top: 0;
                margin: 6px;
                fill: rgba(black, 0.6);
            }
        }

        .watchNoImageContainer {
            width: 208px;
            height: 184px;
            justify-content: center;
            align-items: center;

            & svg {
                color: #B5B5B5;
            }

            .watchNoImage {
                width: 150px;
            }

            .watchNoImageText {
                color: #B5B5B5;
            }
        }

        .otherImagesContainer {
            display: flex;
            flex-direction: row;
            overflow-x: hidden;
            overflow-y: hidden;
            width: 208px;
            height: 43px;
            margin-top: 8px;
            position: relative;

            .thumbnailImageFirst {
                border-radius: 4px;
                width: 64px;
                height: 43px;
            }

            .thumbnailImage {
                border-radius: 4px;
                margin-left: 8px;
                width: 64px;
                height: 43px;
            }

            .moreContainer {
                border-radius: 4px;
                background-color: rgba(#000000, 0.57);
                width: 64px;
                height: 43px;
                position: absolute;
                left: 144px;
                display: flex;
                justify-content: center;
                align-items: center;

                .moreText {
                    color: white;
                    font-size: 28px;
                    font-weight: 400;
                }
            }
        }
    }

    .detailsContainer {
        width: 100%;

        .titleAndReserveContainer {
            display: grid;
            grid-template-columns: 1fr auto;
            margin: 16px 8px;            

            .brandText {
                color: #1D3053;
                font-size: 18px;
                font-weight: 300;
                text-transform: uppercase;
            }
    
            .modelText {
                margin-top: 8px;
                color: #000000;
                font-size: 16px;
                font-weight: 200;
                text-transform: uppercase;
            }
    
            .yearText {
                margin-top: 8px;
                margin-bottom: 16px;
                color: rgba(#000000, 0.7);
                font-size: 13px;
                font-weight: 400;
            }
            
            .reserveContainer {
                margin-left: 16px;
                height: 100%;
                justify-content: center;
        
                .reserveText {
                    color: rgba(#1D3053, 0.78);
                    font-size: 13px;
                    font-weight: 300;
                }
        
                .reserveValueText {
                    color: #1D3053;
                    font-size: 18px;
                    font-weight: 300;
                }
            }

            .bidDetailsContainer {
                margin-left: 16px;
                height: 100%;
                justify-content: center;

                .currentBidContainer {                
                    .currentBidLabel {
                        font-size: 13px;
                        font-weight: 300;
                        color: rgba(#1d3053, 0.78);
                    }

                    .currentBidValue {
                        margin-top: -5px;
                        font-size: 18px;
                        font-weight: 300;
                        color: #1d3053;
                    }

                    .currentBidNoValue {
                        margin-top: -5px;
                        font-size: 16px;
                        font-weight: 200;
                        color: #1d3053;
                    }
                }

                .spacerContainer {
                    margin: 3px 0 0 0;

                    .spacer {
                        height: 2px;
                        background-color: #A1A1A1;
                        width: 100%;
                    }
                }

                .favouritesContainer {
                    justify-content: center;
                    align-items: center;

                    .bidCountValue {
                        font-size: 14px;
                        font-weight: 300;
                        color: #a38c5e;
                        margin-right: 3px;
                    }

                    .gavelIcon {
                        width: 22px;
                        height: 22px;
                    }

                    .gavelBase {
                        margin-left: -8px;
                        font-size: 22px;
                        font-weight: 400;
                        color: #a38c5e;
                    }

                    .favouritesCountValue {
                        margin-left: 21px;
                        font-size: 14px;
                        font-weight: 300;
                        color: #a38c5e;
                    }

                    .favouriteIcon {
                        margin-left: 3px;
                        width: 16px;
                        height: 16px;
                        color: #a38c5e;
                    }
                }
            }
        }        

        .summaryContainer {
            margin: 0 8px;

            .summaryText {
                font-size: 13px;
                font-weight: 200;
                color: #1d3053;

                ul {
                    list-style: none;

                    li::before {
                        content: "\2022";
                        color: #D2AE6D;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }

            .summarySpacer {
                height: 12px;
            }
        }

        .listingDetailsContainer {
            display: grid;
            grid-template-columns: auto 1fr auto;
            grid-template-rows: auto auto;
            width: 100%;
            margin-top: 24px;
            align-items: center;
            row-gap: 12px;
    
            .listingDetailsItemContainer {
                margin: 0 8px;

                .listingDetailsTitle {
                    color: rgba(#1D3053, 0.78);
                    font-size: 10px;
                    font-weight: 400;
                    text-transform: uppercase;
                }

                .listingDetailsText {

                    color: #1D3053;
                    font-size: 13px;
                    font-weight: 300;
                }
            }
        }        

        .specsHeaderContainer {
            width: 100%;
            margin-top: 24px;
            justify-content: space-between;
            align-items: center;
            
            .specsHeaderText {
                margin-left: 8px;
                color: #1D3053;
                font-size: 14px;
                font-weight: 300;
            }

            .specsButton {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                margin: 0 8px;
                align-items: center;
        
                .specsButtonImage {
                    font-size: 24px;
                }
            }            
        }

        .specsContainer {
            margin: 12px 8px;
            flex-wrap: wrap;
            row-gap: 16px;
            column-gap: 40px;

            .specTitle {
                color: rgba(#1D3053, 0.78);
                font-size: 10px;
                font-weight: 400;
                text-transform: uppercase;
            }

            .specText {
                color: #1D3053;
                font-size: 13px;
                font-weight: 300;
            }
        }

        .conditionHeaderContainer {
            width: 100%;
            margin-top: 24px;
            justify-content: space-between;
            align-items: center;
            
            .conditionHeaderText {
                margin-left: 8px;
                color: #1D3053;
                font-size: 14px;
                font-weight: 300;
            }

            .conditionButton {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                margin: 0 8px;
                align-items: center;
        
                .conditionButtonImage {
                    font-size: 24px;
                }
            }            
        }

        .conditionContentContainer {
        
            .otherImagesContainer {
                display: flex;
                flex-direction: row;
                overflow-x: hidden;
                overflow-y: hidden;
                width: 208px;
                height: 43px;
                margin: 8px 8px 0 8px;
                position: relative;
    
                .thumbnailImageFirst {
                    border-radius: 4px;
                    width: 64px;
                    height: 43px;
                }
    
                .thumbnailImage {
                    border-radius: 4px;
                    margin-left: 8px;
                    width: 64px;
                    height: 43px;
                }
    
                .moreContainer {
                    border-radius: 4px;
                    background-color: rgba(#000000, 0.57);
                    width: 64px;
                    height: 43px;
                    position: absolute;
                    left: 144px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .moreText {
                        color: white;
                        font-size: 28px;
                        font-weight: 400;
                    }
                }
            }

            .conditionText {
                margin: 8px 8px 0 8px;
                color: #1D3053;
                font-size: 11px;
                font-weight: 300;
            }

            .conditionNoInfo {
                margin: 8px 8px 0 8px;
                font-size: 11px;
                font-weight: 300;
                color: rgba(#000000, 0.7);
            }    
        }
    }

    .divider {
        display: flex;
        flex-direction: column;
        align-items: center;        
        width: 100%;
        margin-top: 8px;

        .dividerItem {
            width: calc(100% - 16px);
            min-height: 1px;
            height: 1px;
            background-color: rgba(#000000, 0.42);
        }
    }

    .actionsContainer {
        margin-top: 8px;
        width: 100%;

        .actionButton {
            margin-top: 4px;
            padding: 8px;
            background-color: #1D3053;
            border-radius: 2px;
            width: 100%;
    
            .actionText {
                color: white;
                font-size: 13px;
                font-weight: 300;
                text-transform: none;
            }
        }
    }
}