.root {
    border: 1px solid #cac4d0 !important;
    border-radius: 0 !important;
    margin-left: 4px !important;
    margin-right: 4px !important;
    padding: 16px !important;
    justify-content: flex-start !important;
    display: flex;
    flex-direction: column;
    width: 250px;

    :hover{
        cursor: pointer;
    }
    
    :global(.MuiTypography-root) {
        text-align: left;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .brandContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            .brandTitle {
                font-size: 18px;
                font-weight: 300;
                color: #1d3053;
                flex-grow: 1;
                text-transform: uppercase;
            }

            .favouriteContainer {
                color: #1d3053;

                .favouriteIcon {
                    width: 16px;
                    height: 16px;
                    color: #1d3053;
                }
            }
        }

        .modelContainer {
            display: flex;
            flex-direction: row;

            .modelTitle {
                font-size: 16px;
                font-weight: 200;
                color: rgba(#000000, 0.6);
                flex-grow: 1;
                text-transform: uppercase;
                max-width: 250px;
            }
        }

        .yearContainer {
            display: flex;
            flex-direction: row;
            margin-top: 4px;

            .yearTitle {
                font-size: 13px;
                font-weight: 400;
                color: rgba(#000000, 0.6);
                flex-grow: 1;
            }
        }

        .watchImageContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            margin-top: 12px;

            .watchImage {
                width: 250px;
            }

            .watchNoImageContainer {
                width: 250px;
                height: 166px;                
                justify-content: center;
                align-items: center;

                & svg {
                    color: #B5B5B5;
                }

                .watchNoImage {
                    width: 150px;
                }

                .watchNoImageText {
                    color: #B5B5B5;
                }
            }
        }

        .titleAndValueContainer {
            justify-content: center;
        }

        .titleAndValueContainerGrow {
            justify-content: center;
            flex-grow: 1;
        }

        .titleLabel {
            font-size: 13px;
            font-weight: bold;
            color: rgba(#1D3053C7, 0.78);
            text-align: center;
            text-transform: none;
        }

        .valueContainer {
            align-items: center;
            justify-content: center;
        }

        .valueLabel {
            font-size: 13px;
            font-weight: 300;
            color: #111D32;
            text-align: center;
        }

        .valueLabelLarge {
            font-size: 18px;
            font-weight: 300;
            color: #111D32;
            text-align: center;
        }

        .noBidsText {
            font-size: 13px;
            font-weight: 300;
            color: #111D32;
            text-align: center;
        }

        .spacer {
            flex-grow: 1;
        }

        .infoContainer {
            display: flex;
            flex-direction: row;
            margin-top: 12px;
            width: 100%;
        }

        .endTimeContainer {
            align-items: center;
            justify-content: center;
            color: #e02f29;

            .timerIcon {
                width: 13px;
                height: 13px;
                margin-right: 5px;
            }

            .auctionEndValue {
                font-size: 13px;
                font-weight: 300;
                color: #e02f29;
                text-align: center;
            }    
        }        

        .detailsContainer {
            margin-top: 12px;
            width: 100%;

            .currentBidContainer {
                justify-content: left;
            }

            .noReserveContainerCenter {
                flex-grow: 1;
                justify-items: center;
                align-content: center;
            }

            .noReserveContainerLeft {
                flex-grow: 1;
                justify-items: flex-start;
                align-content: center;
            }

            .noReserveBox {
                background-color: #57A652;
                border-radius: 16px;
                padding: 3px 8px;

                .noReserveText {
                    font-size: 13px;
                    font-weight: 300;
                    color: white;
                }
            }

            .detailsButtonContainer {
                align-items: center;
        
                .detailsButton {
                    background-color: #1d3053;
                    border-radius: 2px;
                    padding: 8px;
                }
        
                .details {
                    font-size: 13px;
                    font-weight: 300;
                    color: #ffffff;
                    text-transform: none;
                }
            }        
        }
    }
}