.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageContainer1 {
        width: 100%;
        overflow: hidden;

        .termsImage {
            width: 100%;
            object-fit: fill;
        }
    }

    .titleContainer {
        width: 100%;
        margin-bottom: 30px;

        .titleText {
            font-size: 16px;
            font-weight: 200;
            color: #1D3053;
            text-transform: uppercase;
            margin: 17px 32px 0 32px;
        }
    }

    .termsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
    }

    .termsContainerMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .termsItems {
            margin: 0 32px 0 32px;
        }
    }
}