.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .headerContainer {
        width: 100%;
        flex-shrink: 0;
    }
    
    .bodyContainer {
        flex-grow: 1;
        overflow: auto;
        min-height: 2em;
    }    
}
