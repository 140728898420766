.conditionIcon {
    fill: #111D32 !important;
    font-size: 14px !important;
    margin-left: 7px;
}

.conditionIconLarge {
    fill: #111D32 !important;
    font-size: 16px !important;
    margin-left: 7px;
}

.clickAwayContainer {
    display: flex;
    padding: 0;
    margin: 0;
}

.tooltipContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 8px;

    .gradeHeader {
        text-align: center;
        color: white;
        font-size: 13px;
        font-weight: 600;        
    }

    .gradeText {
        text-align: center;
        color: white;
        font-size: 13px;
        font-weight: 300;
    }

    .descriptionHeader {
        text-align: left;
        color: white;
        font-size: 13px;
        font-weight: 600;        
    }

    .descriptionText {
        text-align: left;
        color: white;
        font-size: 13px;
        font-weight: 300;
    }
}
