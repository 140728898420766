.root {
    width: 100%;
    border-bottom: 1px solid rgba(#B9B6B2, 0.8);
    margin-bottom: 32px;
    
    .contentContainer {
        display: grid;
        grid-template-columns: 1fr auto;
        width: 100%;

        .questionAndAnswerContainer {
            grid-column: 1;
            display: flex;
            flex-direction: column;

            .questionContainer {
                cursor: pointer;

                .questionText {
                    font-size: 14px;
                    font-weight: 400;
                    color: #1d3053;
                    margin: 12px 0 12px 8px;
                }
            }

            .answerText {
                font-size: 14px;
                font-weight: 200;
                color: #111D32;
                margin: 4px 0 12px 8px;
            }
        }

        .iconContainer {
            grid-column: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 12px 0 8px;

            .iconButton {
                color: rgba(#000000, 0.54);
            }            
        }
    }

}